

































































import Vue from 'vue'
import {NavigationGuardNext, Route} from 'vue-router';
import Component from 'vue-class-component'
import SygniToggleSwitch from "@/components/inputs/SygniToggleSwitch.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniLoader from "@/components/layout/SygniLoader.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniLinkButton from "@/components/buttons/SygniLinkButton.vue";
import SygniInputGroup from "@/components/inputs/SygniInputGroup.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {
  emptyInvestmentClient,
  InvestmentClient,
} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {InvestorSelects, GlobalSelects} from "@/modules/shared/dictionary-store/types";
import {ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {email, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {BSpinner} from "bootstrap-vue";
import EditProfile from '../EditProfile.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import { Watch } from 'vue-property-decorator';
import VueQr from 'vue-qr';
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';

Component.registerHooks(['validations', 'beforeRouteLeave'])
@Component({
  components: {
    VueQr,
    SygniInputError,
    SygniToggleSwitch,
    SygniRadio,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniLinkButton, SygniLoader, SygniInput, SygniInputGroup, SygniContainerTitle, BSpinner, EditProfile, SygniModal},
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects',
    }),
  }
})
export default class EditSecurity extends Vue {

  confirmationCode: string = '';
  twoFactorCode: string = 'test';
  twoFactor: boolean = false;
  isTwoFactorLoading: boolean = false;
  showInfoModal: boolean = false;
  isLoading: boolean = false;

  showModal: boolean = false;

  nextRoute: NavigationGuardNext<Vue> = null;

  hasChanges: boolean = false;
  
  investmentData: InvestmentClient = {} as InvestmentClient;

  selects!: InvestorSelects;

  globalSelects!: GlobalSelects;

  profileData: ProfileDataState = {} as ProfileDataState;

  enableInfoModal() {
    this.showInfoModal = true;
  }

  closeInfoModal() {
    this.showInfoModal = false;
  }

  get requireTwoFactor() {
    return this.$store.getters['auth/requireTwoFactor'];
  }

  async beforeMount() {
    localStorage.removeItem(REDIRECTED_PATH);
    this.twoFactor = this.activeUserData.user.twoFactorAuthEnabled;
    this.$store.commit('investors/setNewInvestor', emptyInvestmentClient);
    this.profileData = this.$store.getters['profile/getProfileData'];
  }

  async toggle2Factor() {
    try {
      this.isTwoFactorLoading = true;
      if(this.twoFactor) {
        const code = await this.$store.dispatch('auth/getTwoFactorCode');
        this.twoFactorCode = code;
      } else {
        if(this.activeUserData.user.twoFactorAuthEnabled) {
          await this.$store.dispatch('auth/disableTwoFactor');
          this.$store.commit('genprox/setTwoFactorAuth', false);
          this.$notify({
            duration: 3000,
            type: 'success',
            title: 'Success',
            text: 'Two factor authentication disabled.'
          })
          this.twoFactor = false;
        }
      }
      this.isTwoFactorLoading = false;
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      });
    }
  }

  async sendConfirmationCode() {
    try {
      await this.$store.dispatch('auth/enableTwoFactor', this.confirmationCode);
      this.$store.commit('genprox/setTwoFactorAuth', true);
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Two factor authentication enabled.'
      })
    } catch(e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      });
    }
  }

  showUpdateMessage(type: string, title: string, text: string) {
    this.isLoading = false;
    this.showModal = false;
    this.showModal = false;

    this.$notify({
      duration: 2500,
      type,
      title,
      text
    });
  }

  handleFieldChange(): void {
    this.hasChanges = true;
    this.$v.$reset();
  }

  async updateProfile(nextRoute: NavigationGuardNext<Vue>) {
    if(!this.hasChanges) return; 

    if(this.profileData.mailingAddressSameResidential) {
      this.profileData.mailingAddress = this.profileData.residentialAddress;
    }

    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('profile/updateProfileData', Object.assign({}, this.profileData));
        this.profileData = this.$store.getters['profile/getProfileData'];
        this.showUpdateMessage('success', 'Changes saved', 'Your profile has been updated.');
        this.hasChanges = false;

        if(nextRoute) nextRoute();

      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.showUpdateMessage('error', 'Error', errorMessage);
      }
    } else {
      this.showUpdateMessage('error', 'Validation error', 'Please check your details.');
    }
  }

  changeRoute() {
    if(this.nextRoute) this.nextRoute();
  }

  async confirmUpdateProfile() {
    await this.updateProfile(this.nextRoute);
  }

  get sendAddresses() {
    let sendAddresses = Object.values(this.profileData.residentialAddress).some(el => el);
        
    if(!sendAddresses) {
      sendAddresses = Object.values(this.profileData.mailingAddress).some(el => el);
    }

    return sendAddresses;
  }

  get twoFactorString(): string {
    return this.twoFactor ? 'ON' : 'OFF';
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if(this.hasChanges) {
      this.showModal = true;
      this.nextRoute = next;
    } else {
      next();
    }
  }

  validations() {
    return {
      profileData: {
        generalData: {
          email: { required, email },
        }
      }
    }
  }

  @Watch('profileData.mailingAddressSameResidential')
  clearMailingAddress(): void {
    this.profileData.mailingAddress.street = null;
    this.profileData.mailingAddress.houseNumber = null;
    this.profileData.mailingAddress.flatNumber = null;
    this.profileData.mailingAddress.city = null;
    this.profileData.mailingAddress.zipCode = null;
    this.profileData.mailingAddress.country = null;
  }
}
